enum Actions {
   // action types
   ADD_BODY_CLASSNAME = "addBodyClassName",
   REMOVE_BODY_CLASSNAME = "removeBodyClassName",
   ADD_BODY_ATTRIBUTE = "addBodyAttribute",
   REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
   ADD_CLASSNAME = "addClassName",
   VERIFY_AUTH = "verifyAuth",
   LOGIN = "login",
   LOGOUT = "logout",
   REGISTER = "register",
   UPDATE_USER = "updateUser",
   FORGOT_PASSWORD = "forgotPassword",
   RESET_PASSWORD = "resetPassword",
   SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
   VERIFY_EMAIL = "verifyEmail",
   RESEND_EMAIL_VERIFICATION = "resendEmailVerification",

   USERS_LIST = "usersList",
   USER_PROFILE = "userProfile",
   ROLES_LIST = "rolesList",
   DIVISION_LIST = "divisionList",

   PROJECTS_LIST = "projectsList",
   PROJECTS_ITEM_BY_ID = "projectItemById"
}

enum Mutations {
   // mutation types
   SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
   PURGE_AUTH = "logOut",
   SET_AUTH = "setAuth",
   SET_USER = "setUser",
   SET_PASSWORD = "setPassword",
   SET_ERROR = "setError",
   SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
   SET_LAYOUT_CONFIG = "setLayoutConfig",
   RESET_LAYOUT_CONFIG = "resetLayoutConfig",
   OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
   OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
   SET_RESPONSE = "setResponse",
}

export { Actions, Mutations };
