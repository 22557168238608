import ApiService from "@/core/services/ApiService";
//import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { useRouter } from "vue-router";

@Module
export default class UserModule extends VuexModule {
   router = useRouter;

   errors = [];

   get getUserModuleErrors(): Array<string> {
      return this.errors;
   }

   @Mutation
   [Mutations.SET_ERROR](error) {
      this.errors = error;
      window.localStorage.setItem("_ftva_error", error);
   }

   // https://staging-portal-api.ftva.com.au/api/admin/roles/list
   @Action
   [Actions.ROLES_LIST]() {
      ApiService.setHeader();
      return new Promise<void>((resolve, reject) => {
         ApiService.get("admin/roles/list")
            .then(({ data }) => {
               resolve(data);
            })
            .catch(({ response }) => {
               this.context.commit(Mutations.SET_ERROR, response); //.data.errors);
               console.log(response, 'Error fetching roles list.')
               // reject()
               resolve(response);
            });
      });
   }

   // https://staging-portal-api.ftva.com.au/api/division/list
   @Action
   [Actions.DIVISION_LIST]() {
      ApiService.setHeader();
      return new Promise<void>((resolve, reject) => {
         ApiService.get("division/list")
            .then(({ data }) => {
               resolve(data);
            })
            .catch(({ response }) => {
               this.context.commit(Mutations.SET_ERROR, response); //.data.errors);
               console.log(response, 'Error fetching division list.')
               // reject()
               resolve(response);
            });
      });
   }

   // https://staging-portal-api.ftva.com.au/api/admin/users/list
   @Action
   [Actions.USERS_LIST]() {
      ApiService.setHeader();
      return new Promise<void>((resolve, reject) => {
         ApiService.get("admin/users/list")
            .then(({ data }) => {
               // this.context.commit(Mutations.SET_USER, data);
               resolve(data);
            })
            .catch(({ response }) => {
               this.context.commit(Mutations.SET_ERROR, response); //.data.errors);
               console.log(response, 'Error when fetching records for USERS list.')
               // reject()
               resolve(response);
            });
      });
   }

   // https://staging-portal-api.ftva.com.au/api/admin/user/profile
   @Action
   [Actions.USER_PROFILE](params) {
      ApiService.setHeader();
      return new Promise<void>((resolve, reject) => {
         ApiService.post("admin/user/profile", params)
            .then(({ data }) => {
               resolve(data);
            })
            .catch(({ response }) => {
               this.context.commit(Mutations.SET_ERROR, response); //.data.errors);
               console.log(response, 'Error fetching user profile.')
               // reject()
               resolve(response);
            });
      });
   }

}
