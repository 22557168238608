import { createStore, Payload } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import UserModule from "@/store/modules/UserModule";
import BodyModule from "@/store/modules/BodyModule";
import ProjectModule from "@/store/modules/ProjectModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";

//import { User, UserAuthInfo } from "@/store/modules/AuthModule";
import VuexPersistence from "vuex-persist";

export interface State {
  auth: AuthModule;
}

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  key: "ftva",
});

config.rawError = true;

const store = createStore<State>({
   modules: {
      AuthModule,
      UserModule,
      BodyModule,
      ProjectModule,
      BreadcrumbsModule,
      ConfigModule,
   },
   plugins: [vuexLocal.plugin],
});

export default store;
