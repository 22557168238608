
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const route = useRoute();

    const routePath = computed(() => route.path);

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
    });
    return {
      routePath,
    };
  },
});
