import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
//import { useRouter } from "vue-router";

import Swal from "sweetalert2/dist/sweetalert2.js";
import router from "@/router";

export interface User {
  name: string;
  lastName: string;
  email: string;
  password: string;
  access_token: string;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
  responses: Array<string>;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  responses = [];
  role: number | null = null;
  access = [];

  //router = useRouter;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  get userRole(): number | null {
    return this.role;
  }

  get userAccess(): Array<string> {
    return this.access;
 }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    const isAuth = this.isAuthenticated;
    return isAuth;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  /**
   * Get authentification response
   * @returns array
   */
  get getResponses(): Array<string> {
    return this.responses;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
    window.localStorage.setItem("_ftva_error", error);
  }

  @Mutation
  [Mutations.SET_RESPONSE](responses) {
    this.responses = responses;
    window.localStorage.setItem("_ftva_response", responses);
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.role = user.user.role;
    this.access = user.user.access;
    this.errors = [];
    JwtService.saveToken(this.user.access_token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user; //user.user.role;
    this.role = user.user.role;
    this.access = user.user.access;
    //window.localStorage.setItem("_ftva", JSON.stringify(user));
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    this.role = null;
    this.access = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("auth/login", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          if (data.code !== 200){
            this.context.commit(Mutations.SET_ERROR, data);
          }
          resolve(); //return data; //
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data); //.errors);
          reject(); //throw response; //
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("auth/register", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data); //.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("auth/reset-password", payload)
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }

  @Action
  [Actions.RESET_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("auth/reset-password", payload)
        .then(({ data }) => {
          //console.log(data)
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  /*  @Action
   [Actions.VERIFY_AUTH]() {
     if (JwtService.getToken()) {
       ApiService.setHeader();
       ApiService.get("auth/verify")
         .then(({ data }) => {
 //          this.context.commit(Mutations.SET_AUTH, data);
           this.context.commit(Mutations.SET_RESPONSE, data);
           return true;
         })
         .catch(({ response }) => {
           const err = response.data.errors;
           this.context.commit(Mutations.SET_ERROR, err);
           return false;
         });
     } else {
       return false;
     }
   }
 */

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise<void>((resolve, reject) => {
        ApiService.get("auth/verify")
          .then(({ data }) => {
            this.context.commit(Mutations.SET_USER, data);
            resolve();
          })
          .catch(({ response }) => {
            //router.push('/sign-in');
            this.context.commit(Mutations.PURGE_AUTH);
            Swal.fire({
              text: "Your session had expired, you will be redirected to the login page.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Go to login page",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              window.location.href = '/sign-in';  
            });
            

            this.context.commit(Mutations.SET_ERROR, response); //.data.errors);

            reject();
          });
      });
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_EMAIL](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(
        "auth/verify-email/" +
          payload.id +
          "/" +
          payload.hash +
          "/" +
          payload.exp +
          "/" +
          payload.sig
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_RESPONSE, data);
          resolve(); //return data; //
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data); //.errors);
          reject(); //throw response; //
        });
    });
  }

  @Action
  [Actions.RESEND_EMAIL_VERIFICATION](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("auth/verify-email/resend", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_RESPONSE, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
