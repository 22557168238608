import {
   createRouter,
   createWebHistory,
   RouteRecordRaw,
   Router,
} from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const meta = {
   requiresAuth: true
}

export default [
   {
      path: '/',
      redirect: "/dashboard",
      component: () => import("@/layout/Layout.vue"),
      children: [
         {
            path: "/admin/users",
            name: "UsersList",
            component: () => import("@/views/admin/Users.vue"),
            meta
         },
      ],
      beforeEnter: (to, from) => {
         // CHECK IF USER IS AUTHENTICATED AND ISADMIN IN ROLE
         const accessToken = JwtService.getToken(); 
         const isAuthenticated = store.getters.isUserAuthenticated;
         const adminRole = store.getters.userRole
         if (!isAuthenticated || accessToken === undefined || adminRole != 1) {
            console.log('hoy')
            return {
               name: process.env.VUE_APP_DEFAULT_PAGE//'dashboard'
            }
         }
      }
   }
]