import {
   createRouter,
   createWebHistory,
   RouteRecordRaw,
   Router,
} from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";


const meta = {
   requiresAuth: true
}

export default [
   {
      path: '/',
      redirect: "/new-project-request",
      component: () => import("@/layout/Layout.vue"),
      children: [
         {
            path: "/user-profile/:userId",
            name: "UserProfile",
            props: true,
            component: () => import("@/views/user/UserProfile/index.vue"),
            children: [
               {
                  path: 'profile-details',
                  name: 'ProfileDetails',
                  props: true,
                  component: () => import("@/views/user/UserProfile/ProfileDetails.vue"),
               },
               {
                  path: 'edit-profile',
                  name: 'EditProfile',
                  props: true,
                  component: () => import("@/views/user/UserProfile/EditProfile.vue"),
               },
               {
                  path: 'access',
                  name: 'Access',
                  props: true,
                  component: () => import("@/views/user/UserProfile/Access.vue"),
               },
            ],
            meta
         },
      ],
      beforeEnter: (to, from) => {
         const accessToken = JwtService.getToken(); 
         const isAuthenticated = store.getters.isUserAuthenticated;
         const adminRole = store.getters.userRole
         if (!isAuthenticated || accessToken === undefined) {
            return {
               name: '/new-project-request'
            }
         }
      }
   }
]