import {
   createRouter,
   createWebHistory,
   RouteRecordRaw,
   Router,
} from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
   {
      path: "/",
      // redirect: "/new-project-request",
      redirect: "/sign-in",
      component: () => import("@/layout/Layout.vue"),
      children: [
         {
            path: "/dashboard",
            name: "dashboard",
            redirect: "/dashboard/overview",
            component: () => import("@/views/dashboard/index.vue"),
            children: [
               {
                  path: "/dashboard/overview",
                  name: "DashboardOverview",
                  component: () => import("@/views/dashboard/Overview.vue"),
               },
               {
                  path: "/dashboard/target",
                  name: "DashboardTargets",
                  component: () => import("@/views/dashboard/Targets.vue"),
               },
               {
                  path: "/dashboard/budget",
                  name: "DashboardBudget",
                  component: () => import("@/views/dashboard/Budget.vue"),
               },
               {
                  path: "/dashboard/users",
                  name: "DashboardUsers",
                  component: () => import("@/views/dashboard/MyProjectUsers.vue"),
               },
               {
                  path: "/dashboard/files",
                  name: "DashboardFiles",
                  component: () => import("@/views/dashboard/MyProjectFiles.vue"),
               },
               {
                  path: "/dashboard/activity",
                  name: "DashboardActivity",
                  component: () => import("@/views/dashboard/MyProjectActivity.vue"),
               },
               {
                  path: "/dashboard/settings",
                  name: "DashboardSetting",
                  component: () => import("@/views/dashboard/MyProjectSetting.vue"),
               }
            ],
            meta: {
               requiresAuth: true,
            },
         },
         {
            path: "/my-projects",
            name: "MyProjects",
            component: () => import("@/views/projects/myprojects/index.vue"),
            props: true,
            meta: {
               requiresAuth: true,
            }
         },
         {
            path: '/my-projects/:projectId',
            name: 'ProjectDetails',
            component: () => import("@/views/project/index.vue"),
            meta: {
               requiresAuth: true,
            },
            props: true,
            children: [
               {
                  path: "overview",
                  name: "ProjectOverview",
                  props: true,
                  component: () => import("@/views/project/Overview.vue"),
               },{
                  path: "access",
                  name: "ProjectAccess",
                  props: true,
                  component: () => import("@/views/project/Access.vue"),
               },
            ],
         },
         {
            path: "/new-project-request",
            name: "new-project-request",
            component: () => import("@/views/projects/New.vue"),
            props: true,
            meta: {
               requiresAuth: true,
            },
         },
         {
            path: "/upload-files",
            name: "upload-files",
            component: () => import("@/views/projects/Upload.vue"),
            meta: {
               requiresAuth: true,
            },
         },
         {
            path: "/projects",
            name: "projects",
            component: () => import("@/views/projects/Index.vue"),
            meta: {
               requiresAuth: true,
            },
         },
         {
            path: "/projects/:id",
            name: "projects-item",
            component: () => import("@/views/projects/Item.vue"),
            meta: {
               requiresAuth: true,
            },
         },
         {
            path: "/review-projects",
            name: "review-projects",
            component: () => import("@/views/projects/Review.vue"),
            meta: {
               requiresAuth: true,
            },
         },
         {
            path: "/media-management",
            name: "media-management",
            component: () => import("@/views/apps/MediaManagement.vue"),
            meta: {
               requiresAuth: true,
            },
         },
         {
            path: "/completed-projects",
            name: "completed-projects",
            component: () => import("@/views/projects/Completed.vue"),
            meta: {
               requiresAuth: true,
            },
         },
         {
            path: "/account-usage",
            name: "account-usage",
            component: () => import("@/views/account/Usage.vue"),
            meta: {
               requiresAuth: true,
            },
         },
         {
            path: "/video-training",
            name: "video-training",
            component: () => import("@/views/apps/VideoTraining.vue"),
            meta: {
               requiresAuth: true,
            },
         },
         {
            path: "/booking-calendar",
            name: "booking-calendar",
            component: () => import("@/views/apps/BookingCalendar.vue"),
            meta: {
               requiresAuth: true,
            },
         },
         {
            path: "/video-equipment-request",
            name: "video-equipment-request",
            component: () => import("@/views/apps/VideoEquipmentRequest.vue"),
            meta: {
               requiresAuth: true,
            },
         },
         {
            path: "/project-communication",
            name: "project-communication",
            component: () => import("@/views/projects/Communication.vue"),
            meta: {
               requiresAuth: true,
            },
         },
      ],
   },
   {
      path: "/",
      component: () => import("@/components/page-layouts/Auth.vue"),
      children: [
         {
            path: "/sign-in",
            name: "sign-in",
            component: () =>
               import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
            beforeEnter: (to, from) => {
               // IF USER IS AUTHENTICATED RESTRICT THIS PAGE
               const accessToken = store.getters.isUserAuthenticated;
               const isAuthenticated = store.getters.isUserAuthenticated;
               if (isAuthenticated && accessToken) {
                  return {
                     name: process.env.VUE_APP_DEFAULT_PAGE, //'dashboard'
                  };
               }
            },
         },
         {
            path: "/sign-up",
            name: "sign-up",
            component: () =>
               import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
            beforeEnter: (to, from) => {
               // IF USER IS AUTHENTICATED RESTRICT THIS PAGE
               const accessToken = store.getters.isUserAuthenticated;
               const isAuthenticated = store.getters.isUserAuthenticated;
               if (isAuthenticated && !accessToken) {
                  return {
                     name: process.env.VUE_APP_DEFAULT_PAGE, //'dashboard'
                  };
               }
            },
         },
         {
            path: "/password-reset",
            name: "password-reset",
            component: () =>
               import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
         },
         {
            path: "/reset-password/:token",
            name: "reset-password",
            component: () =>
               import("@/views/crafted/authentication/basic-flow/ResetPassword.vue"),
         },
         {
            path: "/verify-email/:id/:hash",
            name: "verify-email",
            component: () =>
               import("@/views/crafted/authentication/basic-flow/VerifyEmail.vue"),
         },
      ],
   },
   {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/crafted/authentication/Error404.vue"),
   },
   {
      path: "/:pathMatch(.*)*",
      redirect: "/404",
   },
];

// IMPORT AND MERGE ROUTES FOR A MODULAR WAY
import adminRoutes from './admin'
import userRoutes from './user'
let allRoutes = routes

allRoutes = [...routes, ...adminRoutes, ...userRoutes]

const router = createRouter({
   history: createWebHistory(),
   // routes,
   routes: allRoutes
});

router.beforeEach((to, from) => {
   // reset config to initial state
   store.commit(Mutations.RESET_LAYOUT_CONFIG);

   // const accessToken = store.getters.currentUser.access_token;
   const accessToken = store.getters.isUserAuthenticated;
   const isAuthenticated = store.getters.isUserAuthenticated;

   // RESTRICT ACCESS WHEN PAGE REQUIRES AUTH
   if (to.meta.requiresAuth && !isAuthenticated && !accessToken) {
      return {
         name: "sign-in",
      };
   }

   // if(to.meta){

   // }

   /*  // temporarily disabled until fixed
    store.dispatch(Actions.VERIFY_AUTH)
        .then(() => {
        console.log('then');
        //const responses = window.localStorage.getItem("_ftva_response");
           const resp = store.getters.getResponses;
        //router.push({ name: "dashboard" });
        })
        .catch(() => {
        console.log('catch');
           //console.log(store.getters.getErrors);
        //router.push({ name: "sign-in" });
        });
  */
   // Scroll page to top on every route change
   setTimeout(() => {
      window.scrollTo(0, 0);
   }, 100);
});

export default router;
